.score {
  position: fixed;
  top: 16px;
  left: 32px;
  color: white;
  font-family: "VT323", monospace;
  filter: drop-shadow(0 0 2px black);
  z-index: 100;
}

.score .star {
  width: 48px;
  height: 48px;
}

.score .text {
  display: inline-block;
  margin-top: -4px;
  vertical-align: top;
  font-size: 48px;
  height: 48px;
}
