.state-controls {
  background: #333;
  padding: 4px;
}

.state-controls h1 {
  margin: 4px;
}

.state-controls button,
.state-controls input {
  width: 128px;
  height: 32px;
  margin: 4px;
  font-weight: bold;
  text-align: center;
}
