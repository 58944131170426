.task-view {
  position: relative;
  height: 100%;
  width: 100%;
}

.task-view > * {
  position: absolute;
  height: 100%;
  width: 100%;
  animation: fadein 1s ease-in-out;
}

.task-view .image {
  background-color: black;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.task-view .video {
  background-color: black;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.01;
  }
  100% {
    opacity: 1;
  }
}
